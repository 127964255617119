import React, { useEffect } from "react";
import { PublicRoutes } from "./routes";
import { ToastContainerComponent } from "../src/components/Toast";
import TagManager from "react-gtm-module";

function App() {
  return (
    <>
      <ToastContainerComponent />
      <PublicRoutes />
    </>
  );
}

export default App;
