import React from "react";
import styles from "./index.module.scss";

interface Laoding {
  isLoading?: boolean;
}

const PreLoader: React.FC<Laoding> = ({ isLoading }) => {
  return (
    <div
      id="status"
      className={`${styles.js} ${isLoading ? "" : styles.fadeOut}`}
    >
      <div className={`${styles.preloaderElement}`}></div>
    </div>
  );
};

export default PreLoader;
