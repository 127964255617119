import { createSlice } from "@reduxjs/toolkit";

const initialStates = {
  userAddress: "",
  singleUserReferralHistory: [],
};

export const referralSlice = createSlice({
  name: "referral",
  initialState: initialStates,
  reducers: {
    addReferralCodeSlice: (state, action) => {
      state.userAddress = action.payload;
    },

    getSingleUserReferralHistorySlice: (state, action) => {
      state.singleUserReferralHistory = action.payload;
    },
  },
});

export const { addReferralCodeSlice, getSingleUserReferralHistorySlice } =
  referralSlice.actions;
