import React from "react";
const Helmet = React.lazy(() => import("react-helmet"));
function PreloadImages() {
  return (
    <Helmet>
      <link
        rel="preload"
        href={"/images/solana-banner-image.webp"}
        as="image"
      />
      <link rel="preload" href={"/images/banner-mobile.webp"} as="image" />
      <link
        rel="preload"
        href={"/images/choose-backgroundimage1.webp"}
        as="image"
      />
      <link
        rel="preload"
        href={"/images/choose-backgroundimage2.webp"}
        as="image"
      />
      <link rel="preload" href={"/images/servide-main-image.webp"} as="image" />
      <link
        rel="preload"
        href={"/images/solana-volume-market-maker-contact.webp"}
        as="image"
      />
      <link
        rel="preload"
        href={"/images/solana-volume-market-maker-contact-mobile.webp"}
        as="image"
      />
    </Helmet>
  );
}

export default PreloadImages;
