import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const options = {
  position: "top-center",
  autoClose: 3000,
  limit: 5,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  toastId: "errorHome1",
};
class ToasterMsg {
  success = (message) => {
    toast.success(message, options);
  };

  error = (message) => {
    toast.error(message, options);
  };

  info = (message) => {
    toast.info(message, options);
  };
}

export const toastMsg = new ToasterMsg();

class Toaster {
  waitingPromis = async (callBackFuction, pendingMsg, successMsg, funds) => {
    let options = {};
    if (funds == "funds") {
      options = {
        pending: pendingMsg,
        success: successMsg,
        toastId: "eror1",
      };
    } else {
      options = {
        pending: pendingMsg,
        success: successMsg,
        toastId: "eror1",
        // error: 'Something Went Wrong.'
        error: {
          render({ data }) {
            if (data !== undefined) {
              return `${data?.data?.message}`;
            } else {
              // console.log("data=", data);
              return "Something went wrong!";
            }
          },
        },
      };
    }
    return await toast.promise(callBackFuction, options).catch((error) => {
      console.log("Toastify Error - ", error);
    });
  };
}

export const toastAlert = new Toaster();

export const ToastContainerComponent = () => {
  return (
    <ToastContainer
      className={"toastMsg"}
      position="top-center"
      autoClose={2000}
      limit={5}
    />
  );
};
