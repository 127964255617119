import React, { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    const loadGA = () => {
      const script = document.createElement("script");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-GZBWNY919F`;
      document.head.appendChild(script);

      const script2 = document.createElement("script");
      script2.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-GZBWNY919F');
      `;
      document.head.appendChild(script2);
    };

    // Check if Cookiebot has already given consent
    if (window.CookieConsent && window.CookieConsent.consent.given) {
      loadGA();
    } else {
      // Listen for Cookiebot consent change
      window.addEventListener("CookieConsentOnLoad", () => {
        if (window.CookieConsent.consent.given) {
          loadGA();
        }
      });
    }
  }, []);

  return null;
};

export default GoogleAnalytics;
